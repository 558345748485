<template>
    <div class="sports_bet_list">
        <el-table
                :data="betList"
                style="width: 100%"
                border>
            <el-table-column
                    fixed
                    label="베팅번호"
                    width="80">
                <template slot-scope="scope">
                    {{scope.row.id}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="회원"
                    width="140">
                <template slot-scope="scope">
                    <span class="badge badge-success">{{scope.row.user.nickname}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="경기유형"
                    width="150">
                <template slot-scope="scope">
                    {{scope.row.kindConfig.kindName}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="회차"
                    width="80">
                <template slot-scope="scope">
                    {{scope.row.leisureGame.sequence}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="선택값"
                    width="230">
                <template slot-scope="scope">
                    <span class="text-blue" style="font-weight: bold">({{scope.row.selectedValueText}})</span>
                    <span>{{scope.row.attributeConfig.attrName}}</span>
                    <span v-if="scope.row.leisureGame.homeTeamName != null">[{{scope.row.leisureGame.homeTeamName}} vs {{scope.row.leisureGame.awayTeamName}}]</span>
                    <span v-if="scope.row.leisureGame.homeTeamScore >=0 && scope.row.leisureGame.awayTeamScore>=0">[{{scope.row.leisureGame.homeTeamScore}}:{{scope.row.leisureGame.awayTeamScore}}]</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="베팅시간"
                    width="120">
                <template slot-scope="scope">
                    {{scope.row.createTime|datef('MM-DD HH:mm:ss')}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="IP"
                    width="120">
                <template slot-scope="scope">
                    {{scope.row.betIp}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="베팅금액"
                    width="80">
                <template slot-scope="scope">
                    {{scope.row.betCash|comma}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="배당"
                    width="50">
                <template slot-scope="scope">
                    {{scope.row.betOdds}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="예상당첨"
                    width="80">
                <template slot-scope="scope">
                    {{scope.row.totalCash|comma}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed
                    label="베팅결과"
                    width="70">
                <template slot-scope="scope">
                    <span v-if="scope.row.betResult == agentConst.SportsBet.BET_RESULT_WAITING">대기중</span>
                    <span v-if="scope.row.betResult == agentConst.SportsBet.BET_RESULT_WIN"
                          class="badge badge-primary">당첨</span>
                    <span v-if="scope.row.betResult == agentConst.SportsBet.BET_RESULT_LOSE"
                          class="badge badge-danger">낙첨</span>
                    <span v-if="scope.row.betResult == agentConst.SportsBet.BET_RESULT_CANCEL" class="badge badge-danger">취소</span>
                    <span v-if="scope.row.betResult == agentConst.SportsBet.BET_RESULT_SPECIALCASE"
                          class="badge badge-danger">적특</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import Vue from "vue";
    import agentConst from "../../common/agent/agentConst";

    export default {
        name: "LeisureGameBetComp2",
        data() {
            return {
                agentConst: agentConst,
                betList: [],
            }
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                }
            },

        },
        methods: {

        },
        watch: {
            list: {
                handler(newVal) {
                    this.betList = newVal
                },
                immediate: true,
                deep: true
            },
        }
    }
</script>

<style scoped>
    .sports_bet_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .table_sports_bet {
        width: 100%;
        margin-bottom: 10px;
    }

    .table_bet_games {
        width: 100%;
    }

    .table_bet_games td {
        line-height: 40px !important;
        border-bottom: 1px solid #cfcfd1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 2px;
    }
</style>