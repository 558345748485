<template>
    <div class="main">
        <agent-info-comp></agent-info-comp>

        <div class="search">
            <div class="refresh" @click="search"><i class="fa fa-refresh"></i></div>
            <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>
            <el-select v-model="leisureGameBet.byField" size="mini" placeholder="검색조건 선택"
                       style="width:130px;margin-left: 5px">
                <el-option key="0" label="아이디" :value="agentConst.SportsBet.SEARCH_BY_USERNAME"></el-option>
                <el-option key="1" label="닉네임" :value="agentConst.SportsBet.SEARCH_BY_NICKNAME"></el-option>
            </el-select>
            <el-input size="mini" style="width: 100px;margin-left: 5px" v-model="leisureGameBet.searchString"
                      placeholder="검색내용"></el-input>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색
            </el-button>
        </div>
        <div class="search">
            <el-select v-model="leisureGameBet.betResult" @change="getleisureGameBetList" size="mini" placeholder="결과선택"
                       style="width:100px;margin-left: 5px">
                <el-option key="13" label="전체" :value="null">전체</el-option>
                <el-option key="14" label="대기" :value="agentConst.SportsBet.BET_RESULT_WAITING">대기</el-option>
                <el-option key="15" label="당첨" :value="agentConst.SportsBet.BET_RESULT_WIN">당첨</el-option>
                <el-option key="16" label="낙첨" :value="agentConst.SportsBet.BET_RESULT_LOSE">낙첨</el-option>
                <el-option key="17" label="취소" :value="agentConst.SportsBet.BET_RESULT_CANCEL">취소</el-option>
                <el-option key="18" label="적특" :value="agentConst.SportsBet.BET_RESULT_SPECIALCASE">적특
                </el-option>
            </el-select>
            <el-select v-model="orderByText" @change="setOrderBy" size="mini" placeholder="정열순"
                       style="width:120px;margin-left: 5px">
                <el-option key="25" label="시간순" value="시간순">시간순</el-option>
                <el-option key="26" label="베팅머니순" value="베팅머니순">베팅머니순</el-option>
                <el-option key="27" label="예상당첨순" value="예상당첨순">예상당첨순</el-option>
            </el-select>
            <el-select v-model="leisureGameBet.kind" @change="getAttrList" size="mini" placeholder="미니게임 종류선택"
                       style="width:150px;margin-left: 15px">
                <el-option label="전체" :value="null">미니게임 종류 전체</el-option>
                <el-option :label="item.kindName" v-for="item in leisureKindList" :value="item.idx">{{item.kindName}}
                </el-option>
            </el-select>
            <el-select v-model="leisureGameBet.attribute" @change="getleisureGameBetList" size="mini"
                       placeholder="미니게임 속성"
                       style="width:150px;margin-left: 15px">
                <el-option label="전체" :value="null">미니게임 속성 전체</el-option>
                <el-option :label="item.attrName" v-for="item in attrList" :value="item.idx">{{item.attrName}}
                </el-option>
            </el-select>
        </div>
        <div class="betstatistic">
            <span :class="{'text-danger':totalWinnerCash < 0,'text-blue':totalWinnerCash>0}">수익금:{{totalWinnerCash|comma}} 원</span>
            <span>총건수:{{totalBetCount|comma}}건</span>
            <span style="padding-left: 20px;">
                        총베팅금액:{{totalBetCash|comma}}원
                    </span>
            <span>
                        베팅진행중금액: {{totalBetingCash|comma}} 원
                    </span>
            <span>당첨금액:{{totalBetWinCash|comma}} 원</span>
            <span>낙첨금액:{{totalBetLoseCash|comma}} 원</span>
            <span>적특:{{totalSpecial|comma}} 원</span>
            <span>취소:{{totalCancel|comma}} 원</span>

        </div>
        <div class="data" style="overflow-y: scroll;max-height: 980px">
            <leisure-game-bet-comp2 :key="100" :list="leisureGameBetList"
                                    @leisureRefresh="leisureRefresh"></leisure-game-bet-comp2>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20,50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>

    import DateSelectorComp from "../../components/administrator/DateSelectorComp";

    import {Loading} from "element-ui";
    import {
        getLeisureAttrListByKindIdx,
        getLeisureGameBetList2,
        getLeisureGameList2,
        getLeisureKindList2
    } from "../../network/agent/commonRequest";
    import LeisureGameBetComp2 from "../../components/agent/LeisureGameBetComp2";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {agentMixin} from "../../common/agent/agentMixin";
    import agentConst from "../../common/agent/agentConst";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";

    export default {
        name: "ManagerLeisureGameBet",
        components: {
            AgentInfoComp,
            PartnerSubSelectorComp,
            LeisureGameBetComp2,
            DateSelectorComp,

        },
        data() {
            return {
                agentConst: agentConst,
                startDate: '',
                endDate: '',
                leisureGameBet: {partnerId: null, user: {}},
                leisureGameBetInfoStatistic: {},
                leisureGameBetInfoStatisticLatelySearch: {},
                leisureGameBetList: [],
                leisureKindList: [],
                attrList: [],
                pageNum: 1,
                pageSize: 20,
                orderBy: 'id DESC',
                orderByText: '시간순',
                pageTotal: 0,
                totalBetCount: 0,
                totalBetCash: 0,
                totalBetingCash: 0,
                totalBetWinCash: 0,
                totalBetLoseCash: 0,
                totalWinnerCash: 0,
                totalSpecial: 0,
                totalCancel: 0,
            }
        },
        methods: {
            search() {
                this.leisureGameBetList = []
                this.attrList = []
                this.getleisureGameBetList()
            },
            getleisureGameBetList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                this.leisureGameBet.startDate = this.startDate
                this.leisureGameBet.endDate = this.endDate
                getLeisureGameBetList2(this.leisureGameBet, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    this.pageTotal = res.data.total;
                    this.leisureGameBetList = res.data.data.betList;
                    this.totalBetCount = res.data.total;
                    this.totalBetCash = res.data.data.cashInfo.totalBetCash;
                    this.totalBetingCash = res.data.data.cashInfo.totalBetingCash;
                    this.totalBetWinCash = res.data.data.cashInfo.totalBetWinCash;
                    this.totalBetLoseCash = res.data.data.cashInfo.totalBetLoseCash;
                    this.totalWinnerCash = res.data.data.cashInfo.totalWinnerCash;
                    this.totalSpecial = res.data.data.cashInfo.totalSpecial;
                    this.totalCancel = res.data.data.cashInfo.totalCancel;
                    loadingInstance.close()
                })

            },

            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getleisureGameBetList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getleisureGameBetList();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
            setPartnerId(parterId) {
                this.leisureGameBet.partnerId = parterId
                this.getleisureGameBetList()
            },
            setOrderBy() {
                if (this.orderByText == '시간순') {
                    this.orderBy = 'id DESC'
                }
                if (this.orderByText == '베팅머니순') {
                    this.orderBy = 'bet_cash DESC'
                }
                if (this.orderByText == '예상당첨순') {
                    this.orderBy = 'total_cash DESC'
                }
                this.getleisureGameBetList()
            },
            leisureRefresh() {
                this.getleisureGameBetList()
            },
            getAttrList(idx) {
                if (null == idx) {
                    this.attrList = []
                    this.leisureGameBet.kind=null;
                    this.leisureGameBet.attribute=null;
                } else {
                    getLeisureAttrListByKindIdx(idx).then(res => {
                        this.attrList = res.data.data
                        console.log(this.attrList)
                    })
                }
                this.getleisureGameBetList()


            },
        },
        created() {
            this.startDate = this.$moment().subtract(3, 'days').format('yyyy-MM-DD')
            //this.startDate = this.$moment().format('yyyy-MM-DD')
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.search()
            getLeisureKindList2({playsSatus: agentConst.YES}).then(res => {
                this.leisureKindList = res.data.data
            })
        }
    }
</script>

<style scoped>

    .betstatistic {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .betstatistic span {
        padding: 5px 10px;
        font-size: 14px;
    }

</style>